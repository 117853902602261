import styled from 'styled-components'

export default function About () {
  return (
    <Wrapper>
      <h2>About page</h2>
    </Wrapper>
  )
}

const Wrapper = styled.div``